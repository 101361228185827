import React from 'react';
import {
	UncontrolledDropdown,
	DropdownMenu,
	DropdownItem,
	DropdownToggle,
} from 'reactstrap';
import { connect } from 'react-redux';
import { history } from '../../../history';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faPowerOff,
	faUserCircle,
	faCog,
} from '@fortawesome/pro-light-svg-icons';

import { profileToggle } from '../../../redux/actions/profile/user-profile-update';

const handleNavigation = (e, path) => {
	e.preventDefault();
	history.push(path);
};

const UserDropdown = props => {
	const isAuthenticated = props.user.login.isAuthenticated;

	function toggleProfileUpdateModal(e) {
		props.profileToggle(!props.profile.userProfile.profileVisible);
		handleNavigation(e, '/');
	}

	return (
		<DropdownMenu right>
			<DropdownItem tag='a' href='#' onClick={e => toggleProfileUpdateModal(e)}>
				<FontAwesomeIcon
					icon={faCog}
					className='font-size-14 mr-50 text-white'
				/>
				<span className='align-middle'>Settings</span>
			</DropdownItem>
			<DropdownItem
				tag='a'
				href='/'
				className='flex flex-align-center'
				onClick={e => {
					e.preventDefault();
					if (isAuthenticated) {
						return props.logout();
					}
				}}>
				<FontAwesomeIcon
					icon={faPowerOff}
					className='font-size-14 mr-50 text-white'
				/>
				<span className='align-middle'>Log Out</span>
			</DropdownItem>
		</DropdownMenu>
	);
};

const NavbarUser = props => {
	return (
		<ul className='nav navbar-nav navbar-nav-user float-right'>
			{props.user.login.isAuthenticated ? (
				<UncontrolledDropdown tag='li' className='dropdown-user nav-item'>
					<DropdownToggle tag='a' className='nav-link dropdown-user-link'>
						<div className='user-nav d-sm-flex d-none flex-align-center'>
							<span className='prim-font regular'>{props.userName}</span>
						</div>
						<span data-tour='user'>
							{props.userImg ? (
								<img
									src={props.userImg}
									className='round'
									height='28'
									width='28'
									alt='avatar'
								/>
							) : (
								<FontAwesomeIcon
									icon={faUserCircle}
									className='font-size-28 text-white'
								/>
							)}
						</span>
					</DropdownToggle>
					<UserDropdown {...props} />
				</UncontrolledDropdown>
			) : (
				<div
					className='b-width-none ec-no-background bold flex flex-align-center m-r-spacer-xs p-spacer-none cursor-pointer hover-grow'
					onClick={e => handleNavigation(e, '/login')}>
					<div
						className='user-nav d-sm-flex flex-align-center flex'
						id='eco-nav-sign-in'>
						<span data-tour='user'>
							<FontAwesomeIcon
								id='eco-nav-sign-in-svg'
								icon={faUserCircle}
								className='font-size-28 text-white'
							/>
						</span>
						<span
							id='eco-nav-sign-in-text'
							className='m-l-spacer-xs prim-font regular'>
							Sign In
						</span>
					</div>
				</div>
			)}
		</ul>
	);
};

const mapStateToProps = state => {
	return {
		user: state.auth,
		profile: state.profile,
	};
};

export default connect(mapStateToProps, { profileToggle })(NavbarUser);
