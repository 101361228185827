import {
  SHOW_PROFILE,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_ERROR
} from "../../actions/profile/user-profile-update";

export const userProfile = (
  state = {
    profileVisible: false,
    isUpdating: false,
    info: {},
    errorMessage: {}
  },
  action
) => {
  switch (action.type) {
    case SHOW_PROFILE:
      return Object.assign({}, state, {
        ...state,
        profileVisible: action.profileVisible
      });
    case UPDATE_PROFILE_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        info: action.info
      });
    case UPDATE_PROFILE_ERROR:
      return Object.assign({}, state, {
        ...state,
        errorMessage: action.error
      });
    default: {
      return state;
    }
  }
};
