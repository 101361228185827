import {
  SHOW_ALL_NOTES,
  SHOW_CURRENT_NOTE
} from "../../actions/notes/userNoteActions";

export const userNotes = (
  state = {
    errorMessage: "",
    notes: {},
    note: {}
  },
  action
) => {
  switch (action.type) {
    case SHOW_ALL_NOTES:
      return Object.assign({}, state, {
        ...state,
        notes: action.notes
      });
    case SHOW_CURRENT_NOTE:
      return Object.assign({}, state, {
        ...state,
        note: action.note
      });
    default: {
      return state;
    }
  }
};
