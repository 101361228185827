import React from "react";
import logo from "../../../assets/img/logo/logo.png";
import "../../../assets/scss/components/app-loader.scss";
class SpinnerComponent extends React.Component {
  render() {
    return (
      <div className="fallback-spinner vh-100 rel-pos">
        <img
          className="fallback-logo max-w-25 fix-pos abs-center"
          src={logo}
          alt="logo"
        />
        <div className="loading fix-pos abs-center w-350">
          <div className="effect-1 effects w-150"></div>
          <div className="effect-2 effects w-150"></div>
          <div className="effect-3 effects w-150"></div>
        </div>
      </div>
    );
  }
}

export default SpinnerComponent;
