import axios from "axios";
import axiosRetry from "axios-retry";

const URL = process.env.REACT_APP_API_URL;

export const SHOW_ALL_ADS = "SHOW_ALL_ADS";
export const ADS_ERROR = "ADS_ERROR";

axiosRetry(axios, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

const showAds = ads => {
  return {
    type: SHOW_ALL_ADS,
    ads
  };
};

const getError = (response, message) => {
  return {
    type: ADS_ERROR,
    response,
    message
  };
};

export const showAllAds = () => {
  return dispatch => {
    axios
      .get(`${URL}stories/active-stories`)
      .then(response => {
        let ads;
        if (response.data) {
          ads = response.data.data;
          dispatch(showAds(ads));
        }
      })
      .catch(err => {
        dispatch(getError(err.response, err));
        
        console.log(err);
      });
  };
};
