import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Logo from './Logo';

class SidebarHeader extends Component {
	render() {
		return (
			<div className='navbar-header m-l-spacer-lg mob-m-l-spacer-none'>
				<ul className='nav navbar-nav flex-row'>
					<li className='nav-item mr-auto'>
						<a
							style={{cursor:'pointer'}}
							href='https://elevationchurch.org/?utm_source=internal&utm_medium=referral&utm_term=landing-page-view&utm_content=eco-top-button&utm_campaign='
							id='eco-nav-ec-logo'
							className='navbar-brand mob-rel-pos'>
							<Logo className='w-104' />
						</a>
					</li>
				</ul>
			</div>
		);
	}
}

export default SidebarHeader;
