import React from "react";
import { Navbar } from "reactstrap";
import { connect } from "react-redux";
import { logout } from "../../../redux/actions/auth/loginActions";
import SidebarHeader from "../menu/vertical-menu/SidebarHeader";
import NavbarUser from "./NavbarUser";

const ThemeNavbar = props => {
  const user = props.user.login.authUser
    ? props.user.login.authUser
    : props.user.login.currentUser;
  return (
    <React.Fragment>
      <div className="content-overlay" />
      <Navbar className="header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow navbar-light fixed-top min-ht-50 flex-nowrap">
        <SidebarHeader />
        <div className="navbar-wrapper">
          <div className="navbar-container content">
            <div
              className="navbar-collapse d-flex justify-content-end align-items-center"
              id="navbar-mobile"
            >
              {props.horizontal ? (
                <div className="logo d-flex align-items-center max-w-100">
                  <div className="brand-logo mr-50"></div>
                  <h2 className="text-primary brand-text mb-0">
                    Elevation Church
                  </h2>
                </div>
              ) : null}
              <NavbarUser
                userName={user.name}
                userImg={user.avatar}
                logout={props.logout}
              />
            </div>
          </div>
        </div>
      </Navbar>
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    user: state.auth
  };
};

export default connect(mapStateToProps, {
  logout
})(ThemeNavbar);
