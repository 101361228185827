import {
  SHOW_LIVE_EVENT,
  BROADCAST_ERROR,
  SHOW_SCHEDULE,
  UPDATE_STREAM_KEY,
  SEND_SMS,
  SMS_FAILURE,
  SMS_RESET
} from "../../actions/broadcast/broadcastActions";

export const broadcast = (
  state = {
    liveEvent: {},
    schedule: {},
    smsStatus: "",
    stream_key: "",
    errorResponse: {},
    errorMessage: ""
  },
  action
) => {
  switch (action.type) {
    case SHOW_LIVE_EVENT:
      return Object.assign({}, state, {
        ...state,
        liveEvent: action.liveEvent
      });
    case BROADCAST_ERROR:
      return Object.assign({}, state, {
        ...state,
        errorResponse: action.response,
        errorMessage: action.message.message
      });
    case SHOW_SCHEDULE:
      return Object.assign({}, state, {
        ...state,
        schedule: action.schedule
      });
    case UPDATE_STREAM_KEY:
      return Object.assign({}, state, {
        ...state,
        stream_key: action.key
      });
    case SEND_SMS:
      return Object.assign({}, state, {
        ...state,
        smsStatus: "Your message was successfully sent!"
      });
    case SMS_RESET:
      return Object.assign({}, state, {
        ...state,
        smsStatus: ""
      });
    case SMS_FAILURE:
      return Object.assign({}, state, {
        ...state,
        smsStatus: "SMS could not be sent."
      });
    default: {
      return state;
    }
  }
};
