import axios from "axios";
import { toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../../assets/scss/plugins/extensions/toastr.scss";

const URL = process.env.REACT_APP_API_URL;

export const SHOW_ALL_NOTES = "SHOW_ALL_NOTES";
export const SHOW_CURRENT_NOTE = "SHOW_CURRENT_NOTE";

const showNotes = (notes) => {
  return {
    type: SHOW_ALL_NOTES,
    notes,
  };
};

const showCurrentNote = (note) => {
  return {
    type: SHOW_CURRENT_NOTE,
    note,
  };
};

export const showUserNotes = () => {
  return (dispatch) => {
    let token = localStorage.getItem("id_token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(`${URL}efam/notes/index`, {
        headers,
      })
      .then((response) => {
        let notes;
        if (response.data) {
          notes = response.data.data;
          dispatch(showNotes(notes));
        }
      })
      .catch((err) => {
        if (err.response?.status === 404) {
          dispatch(showNotes({}));
        } else {
          console.log(err);
        }
      });
  };
};

export const saveUserNote = () => {
  return (dispatch) => {
    let token = localStorage.getItem("id_token");

    axios({
      method: "POST",
      url: `${URL}efam/notes/store`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        title: "Untitled",
        notes_html: "<p></p>",
      },
    })
      .then((response) => {
        const headers = { Authorization: `Bearer ${token}` };
        if (response.status === 201) {
          let newNote = response.data.data;
          dispatch(showCurrentNote(newNote));

          axios
            .get(`${URL}efam/notes/index`, {
              headers,
            })
            .then((response) => {
              let notes;
              if (response.data) {
                notes = response.data.data;
                dispatch(showNotes(notes));
              }
            })
            .catch((err) => {
              if (err.response?.status === 404) {
                dispatch(showNotes({}));
              } else {
                console.log(err);
              }
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const showUserNote = (id) => {
  return (dispatch) => {
    let token = localStorage.getItem("id_token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(`${URL}efam/notes/show/${id}`, {
        headers,
      })
      .then((response) => {
        let note;
        if (response.data) {
          note = response.data.data;
          dispatch(showCurrentNote(note));
        }
      })
      .catch((err) => console.log(err));
  };
};

export const updateUserNote = (id, newInfo) => {
  return (dispatch) => {
    let token = localStorage.getItem("id_token");
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const dataToSend = new FormData();

    Object.keys(newInfo).forEach((key) => {
      dataToSend.append(key, newInfo[key]);
    });

    dataToSend.append("_method", "PATCH");

    axios
      .post(`${URL}efam/notes/update/${id}`, dataToSend, config)
      .then((response) => {
        const headers = { Authorization: `Bearer ${token}` };

        if (response.status === 204) {
          toast.success("Your Note was successfully updated.", {
            transition: Slide,
          });

          axios
            .get(`${URL}efam/notes/index`, {
              headers,
            })
            .then((response) => {
              let notes;
              if (response.data) {
                notes = response.data.data;
                dispatch(showNotes(notes));
              }
            })
            .catch((err) => {
              if (err.response?.status === 404) {
                dispatch(showNotes({}));
              } else {
                console.log(err);
              }
            });
        }
      })
      .catch((err) => console.log(err));
  };
};

export const deleteUserNote = (id) => {
  return (dispatch) => {
    let token = localStorage.getItem("id_token");

    axios({
      method: "DELETE",
      url: `${URL}efam/notes/delete/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      const headers = { Authorization: `Bearer ${token}` };

      if (response.status === 204) {
        axios
          .get(`${URL}efam/notes/index`, {
            headers,
          })
          .then((response) => {
            let notes;
            if (response.data) {
              notes = response.data.data;
              dispatch(showNotes(notes));
            }
          })
          .catch((err) => {
            if (err.response?.status === 404) {
              dispatch(showNotes({}));
            } else {
              console.log(err);
            }
          });
      }
    });
  };
};
