import { combineReducers } from "redux";
import ads from "./ads/";
import auth from "./auth/";
import broadcast from "./broadcast/";
import userNotes from "./notes/";
import profile from "./profile";

import customizer from "./customizer/";

const rootReducer = combineReducers({
  ads: ads,
  auth: auth,
  broadcast: broadcast,
  notes: userNotes,
  profile: profile,
  customizer: customizer
});

export default rootReducer;
