import { history } from "../../../history";
import axios from "axios";
import { toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../../assets/scss/plugins/extensions/toastr.scss";

import { authorizedUser } from "../auth/loginActions";

const URL = process.env.REACT_APP_API_URL;

export const SHOW_PROFILE = "SHOW_PROFILE";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_ERROR = "UPDATE_PROFILE_ERROR";

const toggleProfile = showOrHide => {
  return {
    type: SHOW_PROFILE,
    profileVisible: showOrHide
  };
};

const updateUserSuccess = updatedInfo => {
  return {
    type: UPDATE_PROFILE_SUCCESS,
    isUpdating: false,
    info: updatedInfo
  };
};

const updateUserFailure = error => {
  return {
    type: UPDATE_PROFILE_ERROR,
    isUpdating: false,
    errorMessage: error
  };
};

export const updateUserProfile = newInfo => {
  return dispatch => {
    let token = localStorage.getItem("id_token");
    const dataToSend = new FormData();

    Object.keys(newInfo).forEach(key => {
      if (newInfo[key].name !== undefined) {
        dataToSend.append(key, newInfo[key], newInfo[key].name);
      } else {
        dataToSend.append(key, newInfo[key]);
      }
    });

    dataToSend.append("_method", "PATCH");

    axios({
      method: "POST",
      url: `${URL}users/profile-update`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: dataToSend
    })
      .then(response => {
        let info;

        if (response.data) {
          info = response.data.data;
          dispatch(updateUserSuccess(info));
          authorizedUser();
          history.push("/");

          toast.success("Your Profile was successfully updated.", {
            transition: Slide
          });
        }
      })
      .catch(err => {
        dispatch(updateUserFailure(err.response));
        console.log(err);
      });
  };
};

export const profileToggle = visibility => {
  return dispatch => {
    dispatch(toggleProfile(visibility));
  };
};
