import { SHOW_ALL_ADS, ADS_ERROR } from "../../actions/ads/adsActions";

export const ads = (
  state = {
    errorMessage: "",
    errorResponse: {},
    ads: {}
  },
  action
) => {
  switch (action.type) {
    case SHOW_ALL_ADS:
      return Object.assign({}, state, {
        ...state,
        ads: action.ads
      });
    case ADS_ERROR:
      return Object.assign({}, state, {
        ...state,
        errorResponse: action.response,
        overallErrorMessage: action.message.message
      });
    default: {
      return state;
    }
  }
};
