import axios from "axios";
import axiosRetry from "axios-retry";
import Centrifuge from "centrifuge";

const URL = process.env.REACT_APP_API_URL;
const centrifuge = new Centrifuge(process.env.REACT_APP_LIVE_EVENT_CF_WS, {});
centrifuge.setToken(process.env.REACT_APP_LIVE_EVENT_CF_TOKEN);

export const SHOW_LIVE_EVENT = "SHOW_LIVE_EVENT";
export const BROADCAST_ERROR = "BROADCAST_ERROR";
export const SHOW_SCHEDULE = "SHOW_SCHEDULE";
export const UPDATE_STREAM_KEY = "UPDATE_STREAM_KEY";
export const SEND_SMS = "SEND_SMS";
export const SMS_FAILURE = "SMS_FAILURE";
export const SMS_RESET = "SMS_RESET";

axiosRetry(axios, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

const showLiveEvent = liveEvent => {
  return {
    type: SHOW_LIVE_EVENT,
    liveEvent
  };
};

const getError = (response, message) => {
  return {
    type: BROADCAST_ERROR,
    response,
    message
  };
};

const showVanitySchedule = schedule => {
  return {
    type: SHOW_SCHEDULE,
    schedule
  };
};

const streamKeyUpdate = key => {
  return {
    type: UPDATE_STREAM_KEY,
    key
  };
};

const sendMessage = () => {
  return {
    type: SEND_SMS
  };
};

const smsError = () => {
  return {
    type: SMS_FAILURE
  };
};

const smsReset = () => {
  return {
    type: SMS_RESET
  };
};

const formatPhoneNumber = phoneNumberString => {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return match[1] + match[2] + match[3];
  }
  return null;
};

export const showLive = () => {
  return dispatch => {
    axios
      .get(`${URL}broadcast/streaming`)
      .then(response => {
        let liveEvent;
        if (response.data) {
          liveEvent = response.data.data;
          dispatch(showLiveEvent(liveEvent));
        }
      })
      .catch(err => {
        dispatch(getError(err.response, err));

        console.log(err);
      });
  };
};

export const showSchedule = () => {
  return dispatch => {
    axios
      .get(`${URL}schedules/upcoming-schedule/elevation-church`)
      .then(response => {
        let vanitySchedule;
        if (response.data) {
          vanitySchedule = response.data.data;
          dispatch(showVanitySchedule(vanitySchedule));
        }
      })
      .catch(err => {
        dispatch(getError(err.response, err));

        console.log(err);
      });
  };
};

export const saveEfamReactions = reaction => {
  return dispatch => {
    const dataToSend = new FormData();

    Object.keys(reaction).forEach(key => {
      dataToSend.append(key, reaction[key]);
    });

    axios({
      method: "POST",
      url: `${URL}efam/reactions/store/elevation-church`,
      data: dataToSend
    }).catch(err => {
      dispatch(getError(err.response, err));

      console.log(err);
    });
  };
};

export const toggleUpdate = toggleName => {
  return dispatch => {
    let token = localStorage.getItem("id_token");
    let config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    axios
      .get(`${URL}admin/notifications/toggle/${toggleName}`, config)
      .catch(err => {
        dispatch(getError(err.response, err));

        console.log(err);
      });
  };
};

export const sendSms = sms => {
  return dispatch => {
    axios
      .post(`${URL}notifications/sms/share-live-experience`, {
        to_number: `1${formatPhoneNumber(sms.to_number)}`,
        message: `From: ${sms.from_name}\n\n${sms.message}\nhttp://ele.vc/Brsldz`
      })
      .then(() => {
        dispatch(sendMessage());
      })
      .catch(err => {
        dispatch(smsError());

        console.log(err);
      });
  };
};

export const resetSms = () => {
  return dispatch => {
    dispatch(smsReset());
  };
};

export const updateStreamKey = key => {
  return dispatch => {
    dispatch(streamKeyUpdate(key));
  };
};
